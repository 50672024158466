import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import ImageTextModule from "../components/ImageTextModule/ImageTextModule"
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import Location from "./../components/Location/location.js"
import Instagram from "./../components/Instagram/Instagram"

const Index = () => {
  return (
    <>
      <Seo title="Wing Sing" description='Authentic, Wholesome and Flavourful' />
      <Layout>
        <BannerModule />
        <ImageTextModule
          title="Flavours of Joy"
          image="https://images.unsplash.com/photo-1525755662778-989d0524087e?q=80&w=1674&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          content="Chinese cuisine is a treasure in itself, for there is no stress that cannot be eased, no challenge that cannot be overcome, no joy that cannot be amplified, between the first bite and the last."
        />
        {/* <BasicTextModule
          title="Our Journey"
          content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis  aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
        /> */}
        <ImageTextModule
          title="Rice is Life"
          image="https://images.unsplash.com/flagged/photo-1556742524-750f2ab99913?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          menu
          content="
          Wing Sing offers authentic Cantonese cuisine, from flavorful stir-fries to classic noodle dishes and hearty rice plates. You can’t buy happiness, but you can buy delicious food. And that’s the same thing. Good food is good mood. So, treat yourself to something delightful! Feel free to check out our menu"
        />
        <Location />
        <Instagram />
      </Layout>
    </>
  )
}

export default Index
