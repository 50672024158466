import * as React from "react"
import { BannerModuleStyles } from "./BannerModuleStyles"
import { StaticImage } from "gatsby-plugin-image"

const BannerModule = () => {
  return (
    <>
      <BannerModuleStyles>
        <div className="banner__image">
          <StaticImage
            className="image"
            src="https://imgur.com/OoIizpC.jpg"
            alt="bubble-tea/dessert"
            placeholder="blurred"
            imgStyle={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </div>
        <div className="banner__description">
          <div className="content">
            <div className="title">
              Authentic,
              <br /> Wholesome and Flavourful
            </div>
            <div className="text">
              Our mission is to deliver the finest quality – authentic,
              wholesome, and flavorful – Chinese cuisine that caters to
              individual tastes and preferences.
            </div>
            <div className="line"></div>
          </div>
        </div>
      </BannerModuleStyles>
    </>
  )
}

export default BannerModule
